import { CourseObjectType } from "../../../types/course";
import { IonButton, IonButtons, IonIcon } from "@ionic/react";

import "./Backdrop.component.scss";
import { playCircle } from "ionicons/icons";

type CourseBackdropPropType = {
  course: CourseObjectType;
};

const BackdropComponent = (props: CourseBackdropPropType) => {
  const { course } = props;
  return (
    <div className="">
      <div
        style={{ background: `url('${course?.media?.image?.raw}')`, backgroundPosition: "center", backgroundSize: "cover" }}
        className="backdrop"
      >
        <div className="backdrop-inner p-5">
          <div className="container">
            <div className="display-4 text-white">{course.name}</div>
            <div className="mt-2 text-white">
              <i>
                {course.org} | {course.number}
              </i>
            </div>
          </div>
          <div className="mt-4 ion-margin-end text-white container">{course.short_description}</div>

          <div className="mt-5 container">
            <IonButton
              size="large"
              color="primary"
              shape="round"
              className="un-capitalize"
              href={`/learning/${course.id}`}
            >
              <IonIcon slot="start" icon={playCircle} />
              Commencer
            </IonButton>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BackdropComponent;
