import React, { useState } from "react";
import { IonInput } from "@ionic/react";
import SpinnerComponent from "../shared/loaders/spinner";
import authService from "../../pages/auth/login/redux/service";

const LoginComponent: React.FC = () => {
  const [user, setUser] = useState({
    password: "",
    username: "",
  });

  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState({});

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const form = new FormData();
    form.append("email_or_username", user.username);
    form.append("next", "https://cours.apsna.fr/dashboard");
    form.append("password", user.password);

    setSubmitting(true);
    setError("");
    try {
      const response = await authService.loginUser(form);
      if (response?.data?.success) {
        window.location.assign(response.data.redirect_url);
      }
    } catch (e: any) {
      console.log(e?.response?.data);
      setError(e?.response?.data);
    }

    setSubmitting(false);
  };
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-6 col-sm-12 offset-lg-3">
            <h2>Connexion</h2>
            <div className="card bg-white">
              <div className="card-body">
                <form action="#" onSubmit={handleSubmit}>
                  <div className="mt-4">
                    <label htmlFor="">Nom d'utilisateur</label>
                    <IonInput
                      disabled={submitting}
                      value={user.username}
                      onIonChange={(event) => {
                        setUser((prevState: any) => {
                          return { ...prevState, username: event.target.value };
                        });
                      }}
                      name="username"
                      required
                      type="text"
                      placeholder="Email ou nom d'utilisateur"
                    />
                  </div>

                  <div className="mt-4">
                    <label htmlFor="">Mot de passe</label>
                    <IonInput
                      name="password"
                      type="password"
                      disabled={submitting}
                      value={user.password}
                      required
                      onIonChange={(event) => {
                        setUser((prevState: any) => {
                          return { ...prevState, password: event.target.value };
                        });
                      }}
                      placeholder="Votre mot de passe"
                    />
                  </div>
                  <div className="mt-4">
                    <button
                      disabled={submitting}
                      className="btn btn-primary form-control"
                    >
                      Connexion
                    </button>
                  </div>
                  <div className="mt-4">
                    <span>Je n'ai pas de compte </span>
                    <span className="ml-2">
                      <a href="/register">Créer un compte</a>
                    </span>
                  </div>
                </form>
                {submitting && <SpinnerComponent />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginComponent;
