import React, { useRef } from "react";
import HeaderComponent from "../../../components/shared/header";
import RegisterComponent from "../../../components/auth/Register.component";
import { IonContent } from "@ionic/react";

const Register: React.FC = () => {
  const ionContentRef = useRef<HTMLIonContentElement>(null);
  return (
    <>
      <HeaderComponent />
      <IonContent ref={ionContentRef} color="light">
        <RegisterComponent />
      </IonContent>
    </>
  );
};

export default Register;
