import React, { useRef } from "react";
import HeaderComponent from "../../../components/shared/header";
import { IonContent } from "@ionic/react";
import LoginComponent from "../../../components/auth/LoginComponent";

const Login: React.FC = () => {
  const ionContentRef = useRef<HTMLIonContentElement>(null);
  return (
    <>
      <HeaderComponent />
      <IonContent ref={ionContentRef} color="light">
        <LoginComponent />
      </IonContent>
    </>
  );
};

export default Login;
