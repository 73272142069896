import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonItem,
  IonText,
  IonToolbar,
} from "@ionic/react";

import "./Header.component.scss";

import logo from "../../../assets/images/logo.png";
import { menuOutline } from "ionicons/icons";
import LoginButtonComponent from "./LoginButton.component";

type HeaderComponentPropTypes = {
  color?: string;
  activeTab?: string;
};

const HeaderComponent = ({ color, activeTab }: HeaderComponentPropTypes) => {
  return (
    <IonHeader>
      <IonToolbar color={color ?? "primary"} className="ion-padding-horizontal">
        <a href="/" className="logo-btn">
          <img className="header-logo" src={logo} alt="Vehseh Learning" />
        </a>

        <IonButtons slot="end" className="ion-padding-horizontal menu-btns">
          <LoginButtonComponent />
          <IonButton href="/register" className="un-capitalize">
            S'inscrire
          </IonButton>
        </IonButtons>

        <IonButton slot="end" className="menu-icon-btn">
          <IonIcon slot="icon-only" icon={menuOutline}></IonIcon>
        </IonButton>
      </IonToolbar>
    </IonHeader>
  );
};

export default HeaderComponent;
