import {
  IonCard,
  IonCol,
  IonGrid,
  IonProgressBar,
  IonRow,
  IonText,
} from "@ionic/react";
import React from "react";
import { CourseObjectType } from "../../../types/course";
import { slugifyName } from "./utils";

import "./Course.component.scss";
import { Button } from "react-bootstrap";

type CourseComponentProptypes = {
  course: CourseObjectType;
  showProgress?: boolean | undefined;
};

const CourseComponent = (props: CourseComponentProptypes) => {
  const { course, showProgress } = props;
  console.log(course)
  return (
    <a
      href={`/courses/${slugifyName(course.name)}/${course.course_id}`}
      color="transparent"
    >
      <div
        className="card_v"
        style={{ background: `url('${course.media?.image?.raw}')`, backgroundPosition: "center center", backgroundSize: "contain" }}
      >
        <div className="overlay"></div>
        <div className="content">
          <h4>{course.name}</h4>
          <h6>
            <i>
              {course.org} | {course.number}
            </i>
          </h6>
        </div>
        <div className="fav">
          <i className="far fa-heart"></i>
        </div>
      </div>
    </a>
  );
};

export default CourseComponent;
