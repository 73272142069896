import React, { useEffect } from "react";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonSlide,
  IonSlides,
  IonText,
} from "@ionic/react";

import { useSelector } from "react-redux";
import { CourseObjectType } from "../../../types/course";

import "./FreeLibrary.component.scss";
import CourseComponent from "../../shared/course";
import { ReduxStatus } from "../../../utils/constants";
import Spinner from "../../shared/loaders/spinner";
import { store } from "../../../app/store";
import { getCourses } from "../../../pages/products/skills/redux/thunks";

const FreeLibraryComponent = () => {
  const { courses, status } = useSelector((state: any) => state.skills);

  useEffect(() => {
    if (status === ReduxStatus.empty) {
      store.dispatch(getCourses({}));
    }
  });

  return (
    <>
      {status === ReduxStatus.loaded && (
        <>
          <IonText>
            <br />
            <h3>Formations disponibles</h3>
            <span>
              Formation avec des étapes pratiques en matière de santé numérique
            </span>
          </IonText>
          <IonGrid>
            <IonRow>
              {courses.map((item: CourseObjectType) => (
                <IonCol
                  key={`${item.id}-free`}
                  sizeSm="12"
                  sizeMd="6"
                  sizeLg="4"
                  sizeXs="12"
                >
                  <CourseComponent course={item} />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </>
      )}
    </>
  );
};

export default FreeLibraryComponent;
