import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";

import "./Learning.page.scss";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import ContentsComponent from "../../components/course-details/table-of-contents/Contents.component";
import DashboardHeader from "../../components/dashboard/header";
import { useSelector } from "react-redux";
import { ReduxStatus } from "../../utils/constants";
import { store } from "../../app/store";
import { getCourseOutline } from "./redux/thunks";
import Spinner from "../../components/shared/loaders/spinner";

interface MatchParams {
  courseId: string;
  blockId: string;
}

const iframeParams: string =
  "?show_title=0&show_bookmark_button=0&recheck_access=1&view=student_view";

const LearningPage: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const [loading, setLoading] = useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);
  const { outline, status } = useSelector((state: any) => state.learning);
  const { user } = useSelector((state: any) => state.dashboard);
  const [currentBlockUrl, setCurrentBlockUrl] = useState("");
  const courseId: string = match?.params?.courseId;

  useEffect(() => {
    if (status === ReduxStatus.empty) {
      store.dispatch(getCourseOutline(courseId));
    }
  }, [status, courseId]);

  useEffect(() => {
    const blockId: string = match?.params?.blockId;
    if (blockId) {
      setCurrentBlockUrl("xblock/" + blockId + iframeParams);
    }
  }, [match]);

  const handleBlockSelected = (url: string) => {
    setCurrentBlockUrl(url + iframeParams);
    setLoading(true);
  };

  return (
    <>
      <DashboardHeader />

      <IonGrid color="light">
        <IonRow className="">
          {outline && (
            <IonCol sizeLg="3" sizeMd="5" sizeSm="12" sizeXs="12">
              <div className="ion-margin">
                <h5>{outline.display_name}</h5>
                <hr />
                <div className="d-flex justify-content-between">
                  <h6>Plan de cours</h6>
                  <h6>
                    <a
                      onClick={(event) => {
                        event.preventDefault();
                        setHideSidebar((prevState) => !prevState);
                      }}
                      href="#!"
                    >
                      {hideSidebar ? <span>Show</span> : <span>Hide</span>}
                    </a>
                  </h6>
                </div>
              </div>

              {!hideSidebar && (
                <ContentsComponent
                  color="light"
                  handleBlockClick={handleBlockSelected}
                  content={outline.children}
                />
              )}
            </IonCol>
          )}
          <IonCol sizeLg="9" sizeMd="7" sizeSm="12" sizeXs="12">
            {loading && status !== ReduxStatus.loading && <Spinner />}
            {currentBlockUrl && (
              <iframe
                onLoad={() => setLoading(false)}
                src={currentBlockUrl}
                className="learning-frame"
              ></iframe>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>

      {status === ReduxStatus.loading && <Spinner />}
    </>
  );
};

export default LearningPage;
