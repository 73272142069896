import http from "../../../../utils/http";

const USER_LOGIN_ENDPOINT = "/api/user/v2/account/login_session/";
const USER_REGISTER_ENDPOINT = "/api/ee/oidc/v1/register/";

const loginUser = async (payload: any) => {
  return await http.post(USER_LOGIN_ENDPOINT, payload);
};

const registerUser = async (payload: any) => {
  return await http.post(USER_REGISTER_ENDPOINT, payload);
};

const authService = {
  loginUser,
  registerUser,
};

export default authService;
