import React, { ChangeEventHandler, useState } from "react";
import { IonInput } from "@ionic/react";
import authService from "../../pages/auth/login/redux/service";
import SpinnerComponent from "../shared/loaders/spinner";
import { AxiosError } from "axios";

type RegisterError = {
  username: [];
  email: [];
};

const RegisterComponent: React.FC = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    username: "",
  });

  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState({});

  const handleChange: React.ChangeEventHandler<HTMLIonInputElement> = (
    event: React.ChangeEvent<HTMLIonInputElement>
  ) => {
    const { name, value } = event.target;
    console.log(name, value);
    setUser((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const form = new FormData();
    form.append("name", user.name);
    form.append("email", user.email);
    form.append("username", user.username);
    form.append("next", "https://cours.apsna.fr/dashboard");
    form.append("password", user.password);
    form.append("honor_code", "true");

    setSubmitting(true);
    setError("");
    try {
      const response = await authService.registerUser(form);
      if (response?.data?.success) {
        window.location.assign(response.data.redirect_url);
      }
    } catch (e: any) {
      console.log(e?.response?.data);
      setError(e?.response?.data);
    }

    setSubmitting(false);
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-lg-6 col-sm-12 offset-lg-3">
          <h2>Inscription</h2>
          <div className="card bg-white">
            <div className="card-body">
              <form action="#" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="">Nom</label>
                  <IonInput
                    disabled={submitting}
                    value={user.name}
                    onIonChange={(event) => {
                      setUser((prevState: any) => {
                        return { ...prevState, name: event.target.value };
                      });
                    }}
                    required
                    name="name"
                    placeholder="votre nom"
                  />
                </div>
                <div className="mt-4">
                  <label htmlFor="">E-mail</label>
                  <IonInput
                    disabled={submitting}
                    value={user.email}
                    onIonChange={(event) => {
                      setUser((prevState: any) => {
                        return { ...prevState, email: event.target.value };
                      });
                    }}
                    name="email"
                    required
                    type="email"
                    placeholder="votre email"
                  />
                </div>

                <div className="mt-4">
                  <label htmlFor="">Nom d'utilisateur</label>
                  <IonInput
                    disabled={submitting}
                    value={user.username}
                    onIonChange={(event) => {
                      setUser((prevState: any) => {
                        return { ...prevState, username: event.target.value };
                      });
                    }}
                    name="username"
                    required
                    type="text"
                    placeholder="votre not d'utilisateur"
                  />
                </div>

                <div className="mt-4">
                  <label htmlFor="">Mot de passe</label>
                  <IonInput
                    name="password"
                    type="password"
                    disabled={submitting}
                    value={user.password}
                    required
                    onIonChange={(event) => {
                      setUser((prevState: any) => {
                        return { ...prevState, password: event.target.value };
                      });
                    }}
                    placeholder="choisissez votre mot de passe"
                  />
                </div>
                <div className="mt-4">
                  <button
                    disabled={submitting}
                    className="btn btn-primary form-control"
                  >
                    Créer un compte
                  </button>
                </div>
                <div className="mt-4">
                  <span>Vous avez déjà un compte? </span>
                  <span className="ml-2">
                    <a href="/login">Connexion</a>
                  </span>
                </div>
              </form>
              {submitting && <SpinnerComponent />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterComponent;
