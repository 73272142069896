import "./Skill.page.scss";
import FreeLibraryComponent from "../../../components/skills/freelibrary";
import { Container } from "react-bootstrap";
import HeaderComponent from "../../../components/shared/header";
import React, { useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router";
import { useSelector } from "react-redux";
import { ReduxStatus } from "../../../utils/constants";
import { store } from "../../../app/store";
import { getCourses } from "./redux/thunks";
import Spinner from "../../../components/shared/loaders/spinner/Spinner.component";
import { IonContent } from "@ionic/react";
import { styleScrollbar } from "../../../utils/styles";

const SkillPage: React.FC<RouteComponentProps> = ({ match }) => {
  const { courses, status } = useSelector((state: any) => state.skills);
  const ionContentRef = useRef<HTMLIonContentElement>(null);

  useEffect(() => {
    if (status == ReduxStatus.empty) {
      store.dispatch(getCourses({}));
    }
  }, [status, courses]);

  styleScrollbar(ionContentRef);

  return (
    <>
      <HeaderComponent activeTab="skills" color="primary" />
      <IonContent color="light" ref={ionContentRef}>
        <Container className="ion-margin-top">
          <FreeLibraryComponent />
        </Container>
      </IonContent>
      {status === ReduxStatus.loading && <Spinner />}
    </>
  );
};

export default SkillPage;
