import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonHeader,
  IonSearchbar,
  IonText,
  IonToolbar,
} from "@ionic/react";

import logo from "../../../assets/images/logo.png";

import "./Header.component.scss";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { ReduxStatus } from "../../../utils/constants";
import { store } from "../../../app/store";
import { getUserDetails } from "../../../pages/dashboard/redux/thunks";
import { getLoginUrl } from "../../../utils/auth";
import LoginButtonComponent from "../../shared/header/LoginButton.component";

const DashboardHeader: React.FC = () => {
  const { user } = useSelector((state: any) => state.dashboard);

  useEffect(() => {
    if (user?.status === ReduxStatus.empty) {
      store.dispatch(getUserDetails({}));
    }
    if (user.status === ReduxStatus.loadFailed) {
      window.location.assign(getLoginUrl());
    }
  }, [user]);

  const userData: any = user?.data ? user.data[0] : null;

  return (
    <IonHeader>
      <IonToolbar color="primary">
        <IonText slot="start">
          <a href="/">
            <img className="header-logo" src={logo} alt="Vehseh Learning" />
          </a>
        </IonText>
        <IonButton slot="end" className="un-capitalize" href="https://studio.lms.cours.apsna.fr">
          Formateurs
        </IonButton>

        {userData && (
          <>
            <IonAvatar slot="end">
              <img
                className="mt-2 user-avatar"
                alt={userData.username}
                src={userData.profile_image?.image_url_small}
              />
            </IonAvatar>
            <IonButtons slot="end">
              <IonButton className="un-capitalize m-lg-2">
                {userData.name || userData.username}
              </IonButton>
            </IonButtons>
          </>
        )}
      </IonToolbar>
    </IonHeader>
  );
};

export default DashboardHeader;
