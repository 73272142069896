import { useSelector } from "react-redux";
import { CourseObjectType } from "../../../types/course";
import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import CourseComponent from "../../shared/course";
import React, { useEffect } from "react";
import { BASE_API_URL, ReduxStatus } from "../../../utils/constants";
import { store } from "../../../app/store";
import { getUserEnrollments } from "../../../pages/dashboard/redux/thunks";
import Spinner from "../../shared/loaders/spinner";

const EnrollmentsComponent = () => {
  const { enrollments } = useSelector((state: any) => state.dashboard);

  const courses = enrollments?.data?.courses;

  useEffect(() => {
    if (enrollments.status === ReduxStatus.empty) {
      store.dispatch(getUserEnrollments({}));
    }
  }, []);

  console.log(courses);

  return (
    <div className="mb-5">
      <IonText>
        <h3>Tes cours</h3>
        <span>Cours auxquels vous êtes inscrit</span>
        <div className="mt-4">
          <IonGrid>
            <IonRow>
              {courses?.map((item: any) => {
                const courseItem: CourseObjectType = {
                  name: item.display_name_with_default,
                  id: item.course_id,
                  course_id: item.course_id,
                  org: item.display_org_with_default,
                  number: item.display_number_with_default,
                  pacing: "",
                  media: {
                    image: {
                      raw: `${BASE_API_URL}${item.course_image_url}`,
                    },
                  },
                };
                return (
                  <IonCol
                    sizeSm="12"
                    sizeXs="12"
                    sizeLg="4"
                    sizeMd="6"
                    key={`in-progress-course-${item.course_id}`}
                  >
                    <CourseComponent course={courseItem} />
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>
        </div>
        {enrollments.status !== ReduxStatus.empty && !courses?.length && (
          <div className="text-center">
            <i>Pas de cours</i>
          </div>
        )}
      </IonText>
      {enrollments.status === ReduxStatus.loading && <Spinner />}
    </div>
  );
};

export default EnrollmentsComponent;
